/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addFireNotFoundEvent = async (args, callback) => {
    callback(...args);

    const { fireNotFoundEvent } = await import('../../event/notFound');

    fireNotFoundEvent();
};

export default {
    'Route/NoMatch/Component': {
        'member-function': {
            componentDidMount: addFireNotFoundEvent
        }
    }
};
