import {
    BreadcrumbsDispatcher as SourceBreadcrumbsDispatcher
} from 'SourceStore/Breadcrumbs/Breadcrumbs.dispatcher';

/** @namespace Scandipwa/Store/Breadcrumbs/Dispatcher */
export class BreadcrumbsDispatcher extends SourceBreadcrumbsDispatcher {
    /**
     * Get breadcrumbs for category
     * @param {Object} category Category breadcumbs items
     * @return {Array<Object>} Breadcrumbs array
     * @memberof BreadcrumbsDispatcher
     */
    _getCategoryBreadcrumbs(category) {
        const {
            url,
            id,
            name,
            description,
            image,
            breadcrumbs
        } = category;
        const breadcrumbsList = [];

        if (breadcrumbs) {
            breadcrumbs
                .sort((a, b) => a.category_level - b.category_level)
                .forEach((crumb) => {
                    const {
                        category_url,
                        category_name,
                        category_description,
                        category_image,
                        category_is_active,
                        children_count = 0
                    } = crumb;

                    // do not add link to inactive categories
                    if (category_is_active) {
                        breadcrumbsList.push({
                            name: category_name,
                            url: {
                                pathname: category_url,
                                state: {
                                    category: true,
                                    categoryTitle: category_name,
                                    categoryDescription: category_description,
                                    categoryImage: category_image,
                                    categoryChildrenCount: parseInt(children_count, 10)
                                }
                            }
                        });
                    } else {
                        breadcrumbsList.push({
                            url: '',
                            name: category_name
                        });
                    }
                });
        }

        return [
            {
                url: {
                    pathname: url,
                    state: {
                        category: id,
                        categoryTitle: name,
                        categoryDescription: description,
                        categoryImage: image
                    }
                },
                name
            },
            ...breadcrumbsList.reverse()
        ];
    }

    findLongestBreadcrumbs(categories) {
        const { breadcrumbsCategory = {} } = categories.reduce((acc, category) => {
            const { longestBreadcrumbsLength } = acc;
            const { breadcrumbs } = category;

            const breadcrumbsLength = (breadcrumbs || []).length;

            if (!breadcrumbsLength && longestBreadcrumbsLength !== 0) {
                return acc;
            }

            if (longestBreadcrumbsLength === 0) {
                return { ...acc, breadcrumbsCategory: category, longestBreadcrumbsLength: breadcrumbsLength };
            }

            if (breadcrumbsLength <= longestBreadcrumbsLength) {
                return acc;
            }

            return {
                breadcrumbsCategory: category,
                longestBreadcrumbsLength: breadcrumbsLength
            };
        }, {
            breadcrumbsCategory: {},
            longestBreadcrumbsLength: 0
        });

        return breadcrumbsCategory;
    }
}

export default new BreadcrumbsDispatcher();
