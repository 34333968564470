import { connect } from 'react-redux';

import NoMatch from 'Route/NoMatch/NoMatch.component';
import {
    mapDispatchToProps,
    mapStateToProps,
    NoMatchContainer as SourceNoMatchContainer
} from 'SourceRoute/NoMatch/NoMatch.container';
import { noopFn } from 'Util/Common';

/** @namespace Scandipwa/Route/NoMatch/Container */
export class NoMatchContainer extends SourceNoMatchContainer {
    render() {
        return (
            <NoMatch
              { ...this.containerProps() }
              cleanUpTransition={ noopFn }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoMatchContainer);
