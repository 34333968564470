/**
 * MageWorx Ultimate Sales Boost compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { cloneElement, isValidElement, PureComponent } from 'react';

import { ChildrenType } from 'Type/Common.type';

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Component/UserAction/Container */
export class UserActionContainer extends PureComponent {
    static propTypes = {
        children: ChildrenType
    };

    static defaultProps = {
        children: []
    };

    __construct() {
        this.state = {
            isMount: true
        };
    }

    getChildren() {
        const { children } = this.props;

        if (isValidElement(children)) {
            return cloneElement(
                children,
                { unmount: this.unmount.bind(this) }
            );
        }

        return children.map((child) => cloneElement(
            child,
            { unmount: this.unmount.bind(this) }
        ));
    }

    unmount() {
        this.setState({ isMount: false });
    }

    render() {
        const { isMount } = this.state;

        if (!isMount) {
            return null;
        }

        return this.getChildren();
    }
}

export default UserActionContainer;
