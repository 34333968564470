import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery } from 'Util/Request';

import SeoBaseQuery from '../../query/SeoBase.query';
import { getCanonicalUrl } from './SeoBase.action';

/** @namespace Scandiweb/SeoSuite/Store/SeoBase/Dispatcher */
export class SeoBaseDispatcher {
    requestCanonicalUrl(dispatch, identifier) {
        const query = SeoBaseQuery.getCmsPageCanonicalFields(identifier);

        return fetchQuery(query).then(
            /** @namespace Scandiweb/SeoSuite/Store/SeoBase/Dispatcher/SeoBaseDispatcher/requestCanonicalUrl/fetchQuery/then */
            (getCanonical) => {
                dispatch(getCanonicalUrl(getCanonical));
            },
            /** @namespace Scandiweb/SeoSuite/Store/SeoBase/Dispatcher/SeoBaseDispatcher/requestCanonicalUrl/fetchQuery/then/catch */
            (error) => {
                dispatch(showNotification('error', error[0].message));
            }
        );
    }
}

export default new SeoBaseDispatcher();
