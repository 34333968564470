Mosaic.addPlugins([require('/drone/src/build/code/scandipwa/packages/seo-suite/src/plugin/CmsPageContainer.plugin.js')]);
/* eslint-disable no-unused-vars */
import CSS from '@scandipwa/scandipwa/src/util/CSS';
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';

import { CMS_PAGE } from 'Component/Header/Header.config';
import CmsPageQuery from 'Query/CmsPage.query';
import {
    BreadcrumbsDispatcher,
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceRoute/CmsPage/CmsPage.container';
import { LocationType, MatchType } from 'Type/Router.type';
import { scrollToTop } from 'Util/Browser';
import { getDataCacheForQuery } from 'Util/Cache/Cache';
import { debounce } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';
import { getUrlParam, isHomePageUrl } from 'Util/Url';

import CmsPage from './CmsPage.component';
import { LOADING_TIME } from './CmsPage.config';

export {
    BreadcrumbsDispatcher,
    mapDispatchToProps
};

/** @namespace Scandipwa/Route/CmsPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Route/CmsPage/Container */
/**
 * Overridden to:
 * - Parent CmsPage inheritance was removed (because parent class overwrites state defined in child, why? I have no clue, probably Mosaic.)
 * - Use window.dataCache to load CMS if it's already been loaded (to avoid reload in BackForward navigation)
 */
export class CmsPageContainer extends DataContainer {
    static propTypes = {
        match: MatchType.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        setBigOfflineNotice: PropTypes.func.isRequired,
        location: LocationType.isRequired,
        toggleBreadcrumbs: PropTypes.func.isRequired,
        pageIds: PropTypes.number,
        pageIdentifiers: PropTypes.string,
        isOnlyPlaceholder: PropTypes.bool,
        isBreadcrumbsActive: PropTypes.bool,
        isMobile: PropTypes.bool
    };

    static defaultProps = {
        pageIds: -1,
        pageIdentifiers: '',
        isOnlyPlaceholder: false,
        isBreadcrumbsActive: true,
        isMobile: false
    };

    __construct(props) {
        const params = this.getRequestQueryParams();
        const { id, identifier } = params;

        super.__construct(props, `CmsPageContainer-${id || identifier}`);

        this.updateBreadcrumbs();

        this.state = {
            page: {},
            isLoading: true,
            isPageLoaded: false
        };

        if (!id && !identifier) {
            return;
        }

        // Retrieve page from cache if it already exists there
        const rawQueries = [CmsPageQuery.getQuery(params)];

        const dataCache = getDataCacheForQuery(rawQueries);

        if (dataCache) {
            const { cmsPage } = dataCache;

            this.state.page = cmsPage;
            this.state.isLoading = false;
            this.state.isPageLoaded = true;
        }
    }

    requestPage = this.requestPage.bind(this);

    containerRef = createRef();

    componentDidMount() {
        const {
            isOffline,
            isOnlyPlaceholder
        } = this.props;

        scrollToTop();

        const { isLoading } = this.state;

        if (isOffline && isLoading) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        if (!isOnlyPlaceholder) {
            this.requestPage();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
            pageIdentifiers,
            pageIds
        } = this.props;

        const {
            location: { pathname: prevPathname },
            pageIdentifiers: prevPageIdentifiers,
            pageIds: prevPageIds
        } = prevProps;

        if (
            pathname !== prevPathname
            || (prevPageIds !== -1 && pageIds !== prevPageIds)
            || pageIdentifiers !== prevPageIdentifiers
        ) {
            this.requestPage();
        }
    }

    requestPage() {
        const { isOffline } = this.props;
        const params = this.getRequestQueryParams();
        const { id, identifier } = params;

        const {
            actionName: {
                id: preloadedId = null,
                page = {}
            }
        } = window;

        if (!id && !identifier) {
            if (page?.identifier === 'home') {
                this.onPageLoad({ cmsPage: page });
            }

            return;
        }

        // Retrieve page from cache if it already exists there
        const rawQueries = [CmsPageQuery.getQuery(params)];

        const dataCache = getDataCacheForQuery(rawQueries);

        if (dataCache) {
            this.onPageLoad(dataCache);

            return;
        }

        if (
            id === preloadedId
            || (page?.identifier && identifier?.replace(/^\/+/, '') === page?.identifier)
        ) {
            this.onPageLoad({ cmsPage: page });

            return;
        }

        this.setState({ isLoading: true, page: {} });

        this.fetchData(
            rawQueries,
            this.onPageLoad.bind(this),
            () => this.setState({ isLoading: false }),
            isOffline
        );
    }

    setOfflineNoticeSize = this.setOfflineNoticeSize.bind(this);

    setPromoSliderHeight = this.setPromoSliderHeight.bind(this);

    updateBreadcrumbs() {
        const {
            toggleBreadcrumbs,
            isBreadcrumbsActive
        } = this.props;

        toggleBreadcrumbs(isBreadcrumbsActive);
    }

    containerProps() {
        const { isBreadcrumbsActive } = this.props;
        const { page, isPageLoaded, isLoading } = this.state;

        return {
            isBreadcrumbsActive,
            isLoading,
            isPageLoaded,
            page,
            containerRef: this.containerRef,
            setPromoSliderHeight: this.setPromoSliderHeight
        };
    }

    setOfflineNoticeSize() {
        const { setBigOfflineNotice } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            setBigOfflineNotice(true);
        } else {
            setBigOfflineNotice(false);
        }
    }

    onPageLoad({ cmsPage: page }) {
        const {
            location: { pathname },
            updateMeta,
            setHeaderState,
            updateBreadcrumbs
        } = this.props;

        const {
            content_heading,
            meta_title,
            title,
            meta_description,
            meta_keywords
        } = page;

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(page);
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href
        });

        if (!isHomePageUrl(pathname)) {
            setHeaderState({
                name: CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack()
            });
        }

        this.setState({ page, isLoading: false, isPageLoaded: true });
    }

    getRequestQueryParams() {
        const {
            location,
            match,
            pageIdentifiers: identifier,
            pageIds: id
        } = this.props;

        if (identifier) {
            return { identifier };
        }

        if (id && id !== -1) {
            return { id };
        }

        const urlKey = getUrlParam(match, location);

        return {
            identifier: urlKey
        };
    }

    setPromoSliderHeight() {
        const {
            isMobile
        } = this.props;

        const screenWidth = window.innerWidth;

        if (isMobile) {
            const percents = 1.39;
            const minHeight = 543;
            const height = Math.max(screenWidth * percents, minHeight);
            CSS.setVariable(this.containerRef, 'promo-slider-placeholder-height', `${height}px`);
            return;
        }

        const maxSliderWidth = 1440;
        const minSliderWidthWithDynamicHeight = 1300;
        if (screenWidth > maxSliderWidth) {
            const height = 536;
            CSS.setVariable(this.containerRef, 'promo-slider-placeholder-height', `${height}px`);
        } else if (screenWidth > minSliderWidthWithDynamicHeight) {
            const percents = 0.37;
            const height = screenWidth * percents;
            CSS.setVariable(this.containerRef, 'promo-slider-placeholder-height', `${height}px`);
        } else {
            const height = 485;
            CSS.setVariable(this.containerRef, 'promo-slider-placeholder-height', `${height}px`);
        }
    }

    render() {
        return (
            <CmsPage
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
