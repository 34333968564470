import { CMS_PAGE } from 'Component/Header/Header.config';
import CmsPageQuery from 'Query/CmsPage.query';
import { LOADING_TIME } from 'Route/CmsPage/CmsPage.config';
import { debounce } from 'Util/Request';
import { appendWithStoreCode, isHomePageUrl } from 'Util/Url';

export const SeoBaseDispatcher = import(
    /* webpackMode: "eager"*/
    '../store/SeoBase'
);

export const X_DEFAULT = 'uae_en';

export class CmsPageContainerPlugin {
    mapStateToProps = (args, cb) => {
        const [state] = args;

        return ({
            ...cb(...args),
            robots: state.MetaReducer.robots,
            status_code: state.MetaReducer.status_code
        });
    };

    mapDispatchToProps = (args, cb) => {
        const [dispatch] = args;

        return ({
            ...cb(...args),
            getCanonicalUrl: (identifier) => SeoBaseDispatcher.requestCanonicalUrl(dispatch, identifier)
        });
    };

    onPageLoad = (args, callback, instance) => {
        const [{ cmsPage }] = args;
        const params = instance.getRequestQueryParams();

        const {
            content_heading,
            meta_title,
            title,
            meta_description,
            meta_keywords
        } = cmsPage;

        const {
            location: { pathname },
            updateMeta,
            status_code,
            robots,
            setHeaderState,
            updateBreadcrumbs
        } = instance.props;

        const {
            actionName: {
                identifier: preloadedIdentifier = null,
                page: preloadedPage = {}
            }
        } = window;

        debounce(instance.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(cmsPage);

        const {
            mw_canonical_url: {
                url: canonical_url = ''
            } = {}
        } = cmsPage;

        const { location: { origin } } = window;

        const updatedCanonical = canonical_url && canonical_url.includes(origin)
            ? canonical_url : `${origin}${appendWithStoreCode(canonical_url)}`;

        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            status_code,
            robots,
            canonical_url: updatedCanonical || window.location.href
        });

        if (!isHomePageUrl(pathname)) {
            setHeaderState({
                name: CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack()
            });
        }

        instance.setState({ page: cmsPage, isLoading: false, isPageLoaded: true });

        // load from actionName if same
        if (preloadedIdentifier && preloadedIdentifier === params?.identifier) {
            instance.setState({
                cmsPageHreflangs: preloadedPage.mw_hreflangs
            });
            if (preloadedPage.mw_hreflangs && preloadedPage.mw_hreflangs.items) {
                this.renderHreflangTags(preloadedPage.mw_hreflangs.items);
            }

            return;
        }

        instance.fetchData(
            [CmsPageQuery.getQuery(params)],
            ({ cmsPage: page }) => {
                instance.setState({
                    cmsPageHreflangs: page.mw_hreflangs
                });
                if (page.mw_hreflangs && page.mw_hreflangs.items) {
                    this.renderHreflangTags(page.mw_hreflangs.items);
                }
            }
        );
    };

    renderHreflangTags = (hreflangs) => {
        if (hreflangs) {
            this.removePreviousHreflangs();

            const indexOfArAe = hreflangs.findIndex(({ code }) => (code === 'ar-AE'));
            if (indexOfArAe !== -1) {
                const {
                    url
                } = hreflangs[indexOfArAe];

                if (!url.includes('uae_ar')) {
                    const passHttps = 8;
                    const indexOf = url.indexOf('/', passHttps);
                    // eslint-disable-next-line no-param-reassign
                    hreflangs[indexOfArAe].url = `${url.slice(0, indexOf + 1)}uae_ar/${url.slice(indexOf + 1)}`;
                }
            }

            hreflangs.forEach((data) => {
                if (data?.url.indexOf(X_DEFAULT) !== -1) {
                    const hreflangTag = document.createElement('link');
                    hreflangTag.href = data.url !== '' ? data.url : '/';
                    hreflangTag.rel = 'alternate';
                    hreflangTag.hreflang = 'x-default';

                    if (!document.querySelector(`[rel="alternate"][hreflang="x-default"][href="${data.url}"]`)) {
                        document.getElementsByTagName('head')[0].appendChild(hreflangTag);
                    }
                }

                const hreflangTag = document.createElement('link');
                hreflangTag.href = data.url !== '' ? data.url : '/';
                hreflangTag.rel = 'alternate';
                hreflangTag.hreflang = data.code;

                if (!document.querySelector(`[rel="alternate"][hreflang="${data.code}"][href="${data.url}"]`)) {
                    document.getElementsByTagName('head')[0].appendChild(hreflangTag);
                }
            });
        }
    };

    removePreviousHreflangs = () => {
        const elements = document.querySelectorAll('[rel="alternate"]');
        Array.from(elements).forEach((element) => element.remove());
    };

    componentWillUnmount = (args, callback, instance) => {
        const {
            cmsPageHreflangs: { items } = {}
        } = instance.state;

        if (items) {
            items.forEach((data) => {
                const hreflangUrl = data.url !== '' ? data.url : '/';
                const previousHreflang = document.querySelector(
                    `[rel="alternate"][hreflang="${data.code}"][href="${hreflangUrl}"]`
                );

                if (previousHreflang) {
                    previousHreflang.remove();
                }

                const previousHreflangDefault = document.querySelector(
                    `[rel="alternate"][hreflang="x-default"][href="${hreflangUrl}"]`
                );

                if (previousHreflangDefault) {
                    previousHreflangDefault.remove();
                }
            });
        }

        instance.setState({
            cmsPageHreflangs: {}
        });

        callback(...args);
    };
}

const {
    onPageLoad,
    mapStateToProps,
    mapDispatchToProps,
    componentWillUnmount
} = new CmsPageContainerPlugin();

export default {
    'Scandipwa/Route/CmsPage/Container': {
        'member-function': {
            onPageLoad,
            componentWillUnmount
        }
    },
    'Route/CmsPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/CmsPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
