/**
 * MageWorx Ultimate Sales Boost compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Query/ReportProductView/Query */
export class ReportProductViewQuery {
    getQuery(id) {
        return new Field('mageWorxReportProductView')
            .addArgument('input', 'MageWorxReportProductViewInput!', { id });
    }
}

export default new ReportProductViewQuery();
