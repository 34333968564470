/**
 * MageWorx Ultimate Sales Boost compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const PAGE_TYPE_PDP = 'pdp';
export const PAGE_TYPE_CATEGORY = 'category';
export const PAGE_TYPE_CMS = 'cms-page';

export const BACKEND_PAGE_TYPES = {
    [PAGE_TYPE_PDP]: 'product-pages',
    [PAGE_TYPE_CATEGORY]: 'category-pages',
    [PAGE_TYPE_CMS]: 'cms-pages'
};

export const DISPLAY_MODES = {
    POPUP: 'popup',
    HTML_TEXT: 'html-text'
};

export const LAST_REPORTED_PRODUCT_ID = 'mageWorxLastReportedProductId';
