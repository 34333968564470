import { Field } from 'Util/Query';

/** @namespace Scandiweb/SeoSuite/Query/SeoBase/Query */
export class SeoBaseQuery {
    _getItems() {
        const items = new Field('items')
            .addFieldList([
                'code',
                'url'
            ]);

        return items;
    }

    getMwHreflangs() {
        const mwHrefLangs = new Field('mw_hreflangs')
            .addFieldList([
                this._getItems()
            ]);

        return mwHrefLangs;
    }

    getMwCanonicalUrl() {
        const mwHrefLangs = new Field('mw_canonical_url')
            .addFieldList([
                'code',
                'url'
            ]);

        return mwHrefLangs;
    }

    getCmsPageHreflangFields(identifier) {
        if (!identifier) {
            throw new Error('Missing argument `id` or `url_key`!');
        }

        const cmsPageHreflang = new Field('cmsPage')
            .addArgument('identifier', 'String', identifier)
            .addFieldList([
                this.getMwHreflangs()
            ]);

        return cmsPageHreflang;
    }

    getCmsPageCanonicalFields({ id, identifier }) {
        if (!identifier && !id) {
            throw new Error('Missing argument `id` or `url_key`!');
        }

        const cmsPageHreflang = new Field('cmsPage')
            .addFieldList([
                this.getMwCanonicalUrl()
            ]);

        if (identifier) {
            cmsPageHreflang.addArgument('identifier', 'String!', identifier);
        } else if (id) {
            cmsPageHreflang.addArgument('id', 'Int!', id);
        }

        return cmsPageHreflang;
    }
}

export default new SeoBaseQuery();
