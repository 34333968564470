/**
 * MageWorx Ultimate Sales Boost compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Suspense } from 'react';

import { fetchMutation } from 'Util/Request';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';
import getStore from 'Util/Store';

import {
    DISPLAY_MODES,
    LAST_REPORTED_PRODUCT_ID
} from '../component/SocialProof/SocialProof.config';
import UserAction from '../component/UserAction';
import ReportProductViewQuery from '../query/ReportProductView.query';
import { isIntLike } from '../util/util';

const SocialProof = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "mageworx-social-proof" */
    '../component/SocialProof'
));

const addSocialProofPopup = (args, callback) => (
    <>
        { callback(...args) }
        <UserAction>
            <Suspense fallback={ null }>
                <SocialProof displayMode={ DISPLAY_MODES.POPUP } />
            </Suspense>
        </UserAction>
    </>
);

const addSocialProofText = (args, callback, instance) => {
    const { isMobile, isMainProduct } = instance.props;

    if (!isMainProduct) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <Suspense fallback={ <div block="CustomersViewsFallback" /> }>
                <SocialProof displayMode={ DISPLAY_MODES.HTML_TEXT } isMobile={ isMobile } />
            </Suspense>
        </>
    );
};

const reportProductView = async (id) => {
    const state = getStore().getState();
    const {
        mageWorxUltimateSalesBoostConfig: {
            socialProofConfig: { isReportEnabled, isSpwaCompatibilityEnabled }
        }
    } = state.ConfigReducer;

    const lastReportedId = parseInt(
        sessionStorage.getItem(LAST_REPORTED_PRODUCT_ID),
        10
    );

    if (
        !isReportEnabled
        || !isSpwaCompatibilityEnabled
        || (isIntLike(lastReportedId) && lastReportedId === id)
    ) {
        return;
    }

    const success = await fetchMutation(ReportProductViewQuery.getQuery(id));

    if (success) {
        sessionStorage.setItem(LAST_REPORTED_PRODUCT_ID, id);
    }
};

const reportProductViewOnMount = (args, callback, instance) => {
    try {
        const { product: { id } = {} } = instance.props;

        if (isIntLike(id)) {
            reportProductView(id);
        }
    } catch {
        return callback(...args);
    }

    return callback(...args);
};

const reportProductViewOnUpdate = (args, callback, instance) => {
    try {
        const [prevProps] = args;
        const { product: { id: prevId } = {} } = prevProps;
        const { product: { id } = {} } = instance.props;

        if (isIntLike(id) && prevId !== id) {
            reportProductView(id);
        }
    } catch {
        return callback(...args);
    }

    return callback(...args);
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidMount: reportProductViewOnMount,
            componentDidUpdate: reportProductViewOnUpdate
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            render: addSocialProofPopup
        }
    },
    'Route/CategoryPage/Component': {
        'member-function': {
            render: addSocialProofPopup
        }
    },
    'Route/CmsPage/Component': {
        'member-function': {
            render: addSocialProofPopup
        }
    },
    'Component/ProductGallery/Component': {
        'member-function': {
            renderSlider: addSocialProofText
        }
    }
};
