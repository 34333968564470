import PropTypes from 'prop-types';

import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatch from 'Route/NoMatch';
import {
    CmsPage as SourceCmsPage
} from 'SourceRoute/CmsPage/CmsPage.component';
import { getIsHomePage, getIsPremium } from 'Util/Url';

import './CmsPage.override.style';
import 'Component/LandingMenu/LandingMenu.style.scss';

/** @namespace Scandipwa/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPage {
    static propTypes = {
        ...this.propTypes,
        isMobile: PropTypes.bool.isRequired
    };

    renderHeading() {
        const { page: { content_heading } } = this.props;

        if (!content_heading) {
            return null;
        }

        return (
            <div block="CmsPage" elem="HeadingContainer">
                <div block="CmsPage" elem="HeadingWrapper">
                    <h2 block="CmsPage" elem="Heading ContentWrapper">
                        <TextPlaceholder content={ content_heading } />
                    </h2>
                </div>
            </div>
        );
    }

    renderContent() {
        const {
            isLoading,
            page: { content },
            setPromoSliderHeight,
            isMobile
        } = this.props;

        setPromoSliderHeight();

        if (isLoading && !isMobile) {
            return (
                <>
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                    <div block="CmsPage" elem="SectionPlaceholder" />
                </>
            );
        }

        if (!isLoading && !content) {
            return null;
        }

        return <Html content={ content } isPriorityContent />;
    }

    render() {
        const {
            page,
            isBreadcrumbsActive,
            isLoading,
            isPageLoaded,
            containerRef
        } = this.props;
        const { page_width } = page;
        const isPremium = getIsPremium();
        const isHomePage = getIsHomePage();

        if (!isLoading && !isPageLoaded) {
            return <NoMatch />;
        }

        return (
            <main
              block="CmsPage"
              mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive } }
              ref={ containerRef }
            >
                { this.renderHeading() }
                <div block="CmsPage" elem="Wrapper" mods={ { page_width } }>
                    <div block="CmsPage" elem="Content" mods={ { isPremium, isHomePage } }>
                        { this.renderContent() }
                    </div>
                </div>
            </main>
        );
    }
}

export default CmsPageComponent;
